import type { Menus } from '@/common/types'
import Custom404Section from '@/components/core/Custom404Section'
import RootLayout from '@/components/layout/RootLayout'
import { REVALIDATE_TIME } from '@/env'
import type { FooterEntity, PromoEntity } from '@/gql/generated/graphql'
import { Api } from '@/services/api'
import type { GetStaticProps } from 'next'
import { NextSeo } from 'next-seo'
import React, { Fragment } from 'react'

type Props = {
  menus: Menus
  footer: FooterEntity
  promos: PromoEntity[]
  isWhiteTheme?: boolean
  hideLocales?: boolean
  notSticky?: boolean
}

export default function Custom404(props: Props) {
  const title = '404 - WiTopia'
  const desc =
    "We're sorry, but we can't find the page you're looking for. Please visit WiTopia home page or you can also browse our blogs. Hope you'll have better luck there."
  return (
    <Fragment>
      <NextSeo
        title={title}
        description={desc}
        canonical={'https://www.witopia.com/blog'}
        openGraph={{
          title: title,
          description: desc,
        }}
      />
      <RootLayout
        promos={props.promos}
        menus={props.menus}
        notSticky={props.notSticky}
        isWhiteTheme={props.isWhiteTheme}
      >
        <Custom404Section />
      </RootLayout>
    </Fragment>
  )
}

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const [menus, footer, promos] = await Promise.all([
    Api.Menus(locale),
    Api.FooterMenu(),
    Api.PromoBanners(),
  ])
  return {
    props: { menus, footer, promos, notSticky: false, isWhiteTheme: true },
    revalidate: REVALIDATE_TIME,
  }
}
